<template>
  <v-container>
    <Patcher
      :itemValue="itemData.title"
      itemName="title"
      itemLabel="Titel"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="input"
      inputType="text"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.title"
      @emit="itemData.title = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.subject"
      itemName="subject"
      itemLabel="Betreff"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="input"
      inputType="text"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.subject"
      @emit="itemData.subject = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.message"
      itemName="message"
      itemLabel="Inhalt"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="textarea-md"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.message"
      @emit="itemData.message = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.footer"
      itemName="footer"
      itemLabel="Footer"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="textarea-md"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.footer"
      @emit="itemData.footer = $event.value"
    ></Patcher>

    <h2 class="mt-4">Platzhalter</h2>
    <ul class="placeholder-list" style="list-style-type: none">
      <li><strong>LEAD</strong></li>
      <li>%gender%</li>
      <li>%firstName%</li>
      <li>%lastName%</li>
      <li>%email%</li>
      <li><strong>CAMPAIGN</strong></li>
      <li>%recommendationUrl%</li>
      <li><strong>COMPANY</strong></li>
      <li>%companyName%</li>
      <li>%companyStreet%</li>
      <li>%companyStreetNumber%</li>
      <li>%companyZip%</li>
      <li>%companyCity%</li>
      <li>%companyTel%</li>
      <li>%companyUrl%</li>
    </ul>
  </v-container>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "Template",

  components: {
    Patcher,
  },

  props: ["itemData"],

  data() {
    return {
      filePatcherKey: 0,
      patchRoute: "infoEmailsTemplates/",
      fetchingData: false,
    };
  },

  mixins: [apiRequest, helpers],
};
</script>

<style scoped>
ul.placeholder-list {
  padding: 0;
  margin: 1em 0;
  list-style-type: none;
  font-size: 0.9em;
}

ul.placeholder-list li {
  padding: 0.25rem 0;
  margin: 0;
}
</style>
