<template>
  <v-container>
    <v-container class="ma-0 pa-0">
      <v-row
        no-gutters
        class="align-center"
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.05)"
      >
        <v-col cols="3" class="font-weight-bold pa-2 black--text">
          Status
        </v-col>
        <v-col cols="8" class="pa-2">{{ itemData.status }}</v-col>
      </v-row>
    </v-container>

    <Patcher
      :itemValue="itemData.title"
      itemName="title"
      itemLabel="Titel"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="input"
      inputType="text"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :disabled="
        itemData.status === 'pending' || itemData.status === 'completed'
          ? true
          : false
      "
      :previewValue="itemData.title"
      @emit="itemData.title = $event.value"
    ></Patcher>

    <Patcher
      v-if="!fetchingData"
      :itemValue="itemData.infoEmailsTemplates_id"
      itemName="infoEmailsTemplates_id"
      itemLabel="Mailing-Template"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="select"
      inputType=""
      :selectOptions="mailingTemplates"
      selectValueKey="id"
      selectTextKey="title"
      :disabled="
        itemData.status === 'pending' || itemData.status === 'completed'
          ? true
          : false
      "
      :previewValue="itemData.infoEmailsTemplates_id"
      @emit="itemData.infoEmailsTemplates_id = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.activeMemberCampaignsOnly"
      itemName="activeMemberCampaignsOnly"
      itemLabel="An alle Leads aktiver Member-Kampagnen"
      :hideLabel="true"
      :route="patchRoute + itemData.id"
      inputElement="switch"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :disabled="
        itemData.status === 'pending' || itemData.status === 'completed'
          ? true
          : false
      "
      :previewValue="itemData.activeMemberCampaignsOnly"
      @emit="itemData.activeMemberCampaignsOnly = $event.value"
    ></Patcher>

    <Patcher
      v-if="
        !itemData.activeMemberCampaignsOnly ||
        itemData.activeMemberCampaignsOnly === '0'
      "
      :itemValue="itemData.campaign_ids"
      itemName="campaign_ids"
      itemLabel="Kampagnen-IDs"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="input"
      inputType="text"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      hint="Mehrere IDs mit Komma trennen"
      :disabled="
        itemData.status === 'pending' || itemData.status === 'completed'
          ? true
          : false
      "
      :previewValue="itemData.campaign_ids"
      @emit="itemData.campaign_ids = $event.value"
    ></Patcher>

    <v-btn
      v-if="itemData.status !== 'completed'"
      color="green"
      :disabled="activationDisabled()"
      large
      @click="startMailing()"
      class="white--text my-4"
    >
      Mailing starten
    </v-btn>

    <SendTest
      v-if="!activationDisabled()"
      :templateId="itemData.infoEmailsTemplates_id"
      :listId="itemData.id"
    />
  </v-container>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import SendTest from "./SendTest.vue";
export default {
  name: "Mailing",

  components: {
    Patcher,
    SendTest,
  },

  props: ["itemData"],

  data() {
    return {
      filePatcherKey: 0,
      patchRoute: "infoEmailsList/",
      fetchingData: false,
      mailingTemplates: [],
    };
  },

  created: function () {
    this.getMailingTemplates();
  },

  methods: {
    async startMailing() {
      const userChoice = await this.$dialog.confirm({
        text: `Soll das Mailing wirklich eingereicht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.fetchingData = true;
        let response = await this.postRequest(
          `infoEmailsList/${this.itemData.id}/queue`
        );

        if (response.status === 200) {
          this.itemData.status = "pending";

          /* start snackbar */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Das Mailing wurde eingereicht";
          this.$root.snackbarGlobal.snackbarColor = "green";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }

        this.fetchingData = false;
      }
    },

    async getMailingTemplates() {
      this.fetchingData = true;
      let response = await this.getRequest("infoEmailsTemplates");
      this.mailingTemplates = response.data.data;
      this.fetchingData = false;
    },

    activationDisabled() {
      if (
        this.itemData.status === "pending" ||
        this.itemData.status === "completed"
      ) {
        return true;
      } else {
        if (!this.itemData.title || !this.itemData.infoEmailsTemplates_id) {
          // kein titel oder kein template ausgewählt
          return true;
        } else if (
          !this.itemData.activeMemberCampaignsOnly &&
          !this.itemData.campaign_ids
        ) {
          // kein activeMemberCampaignsOnly und keine campaign_ids ausgewählt
          return true;
        }

        return false;
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
