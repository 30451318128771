<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <Mailings />

        <MailTemplates />
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/tech/pages"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import Mailings from "@/components/tech/info-mails/Mailings.vue";
import MailTemplates from "@/components/tech/info-mails/MailTemplates.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
export default {
  name: "InfoMails",

  components: {
    PageHeader,
    MailTemplates,
    Mailings,
    Subnavigation,
  },

  data() {
    return {
      componentKey: 0,
      detailDialog: false,
      itemData: [],
      itemIndex: null,
      pageTitle: "Info-Mails (ALPHA)",
      pageDescription: "Hier werden Mailings verfasst und versendet.",
      search: "",
      headers: [],
      items: [],
      fetchingData: false,
    };
  },

  methods: {},
};
</script>
