<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-text-field
          v-model="email"
          hide-details
          dense
          label="E-Mail Adresse"
          solo
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn
          color="green"
          @click="send()"
          :disabled="validateEmail() ? false : true"
          :loading="fetchingData"
          class="white--text"
        >
          Test senden
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "SendTest",

  components: {},

  props: ["templateId", "listId"],

  data() {
    return {
      fetchingData: false,
      email: null,
    };
  },

  methods: {
    async send() {
      const formData = new FormData();
      formData.append("email", this.email);

      this.fetchingData = true;
      let response = await this.postRequest(
        `infoEmailsList/${this.listId}/test`,
        null,
        formData
      );

      if (response.status === 200) {
        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Test-E-Mail wurde versendet";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }

      this.fetchingData = false;
    },

    validateEmail() {
      // check if this.email is a valid email address
      var re = /\S+@\S+\.\S+/;
      return re.test(this.email);
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
