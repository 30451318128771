<template>
  <v-card elevation="2" class="my-4">
    <v-toolbar dark color="primary" elevation="0">
      <v-toolbar-title>Mailings</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <DataTable
            @tableAction="emittedAction"
            :items="items"
            :headers="headers"
            :itemActions="{
              edit: true,
            }"
            :search="search"
            :preloader="fetchingData"
          />
        </v-col>
      </v-row>

      <v-dialog
        v-model="detailDialog"
        max-width="800px"
        transition="dialog-bottom-transition"
        scrollable
        persistent
        :key="componentKey"
      >
        <v-card>
          <v-toolbar dark color="primary" elevation="0">
            <v-toolbar-title>Mailing</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="detailDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="mt-2">
            <Mailing :itemData="itemData" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-btn dark color="green my-2" @click="createNew">
        <v-icon>mdi-plus</v-icon>
        <span>Mailing</span>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import Mailing from "@/components/tech/info-mails/Mailing.vue";
import DataTable from "@/components/ui/DataTable.vue";
export default {
  created: function () {
    this.getData(); // call it immediatly
  },

  name: "MailTemplates",

  components: {
    Mailing,
    DataTable,
  },

  data() {
    return {
      componentKey: 0,
      detailDialog: false,
      itemData: [],
      itemIndex: null,
      search: "",
      headers: [],
      items: [],
      fetchingData: false,
    };
  },

  methods: {
    async createNew() {
      /* create new entry */
      let response = await this.postRequest("infoEmailsList");

      if (response.status === 200) {
        this.items.push(response.data.data);

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde erfolgreich angelegt.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest("infoEmailsList");
      this.fetchingData = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id", width: "70px" },
          { text: "Status", value: "status" },
          { text: "Titel", value: "title" },
          { text: "Template", value: "infoEmailsTemplates_id" },
          { text: "AMC", value: "activeMemberCampaignsOnly" },
          { text: "Erstellt", value: "created" },
          { text: "", value: "actions", sortable: false, width: "50px" },
        ];

        this.items = response.data.data;
      }
    },

    async deleteItem() {
      const userChoice = await this.$dialog.confirm({
        text: `Soll diese Mailvorlage wirklich gelöscht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // do it
        /* da ich die ID und den INDEX vorher den currentXX Vars zugewiesen habe, kann ich diese nun verwenden */
        let response = await this.deleteRequest(
          "infoEmailsList/" + this.currentItemId
        );
        if (response.status === 200) {
          this.items.splice(this.currentItemIndex, 1); // remove from array
        }
      }
    },

    /* Emitted Actions:
    Hier werden die aus der <DataTable></DataTable> Komponente 'emittete' Werte verarbeitet. 
    Die Komponente übermittelt die Button-Klicks ... editItem etc.*/

    itemAction(action, item, index) {
      if (action === "edit") {
        this.componentKey += 1;
        this.itemData = item;
        this.itemIndex = index;
        this.detailDialog = true;
      }
    },

    emittedAction(value) {
      if (value.action === "deleteItem") {
        /* da wir einen dialog zwischenschalten, muss ich den index und die item id speichern, damit ich diese im Anschluss löschen kann.
        Das löse ich hier mit currentItemId und currentItemIndex */
        this.currentItemId = value.itemId;
        this.currentItemIndex = value.itemIndex;
        this.deleteItem();
      } else if (value.action === "editItem") {
        this.componentKey += 1;
        this.itemData = value.item;
        this.itemIndex = value.index;
        this.detailDialog = true;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
