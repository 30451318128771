var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"align-center",staticStyle:{"border-bottom":"1px solid rgba(0, 0, 0, 0.05)"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"font-weight-bold pa-2 black--text",attrs:{"cols":"3"}},[_vm._v(" Status ")]),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"8"}},[_vm._v(_vm._s(_vm.itemData.status))])],1)],1),_c('Patcher',{attrs:{"itemValue":_vm.itemData.title,"itemName":"title","itemLabel":"Titel","hideLabel":false,"route":_vm.patchRoute + _vm.itemData.id,"inputElement":"input","inputType":"text","selectOptions":"","itemValueKey":"","itemTextKey":"","disabled":_vm.itemData.status === 'pending' || _vm.itemData.status === 'completed'
        ? true
        : false,"previewValue":_vm.itemData.title},on:{"emit":function($event){_vm.itemData.title = $event.value}}}),(!_vm.fetchingData)?_c('Patcher',{attrs:{"itemValue":_vm.itemData.infoEmailsTemplates_id,"itemName":"infoEmailsTemplates_id","itemLabel":"Mailing-Template","hideLabel":false,"route":_vm.patchRoute + _vm.itemData.id,"inputElement":"select","inputType":"","selectOptions":_vm.mailingTemplates,"selectValueKey":"id","selectTextKey":"title","disabled":_vm.itemData.status === 'pending' || _vm.itemData.status === 'completed'
        ? true
        : false,"previewValue":_vm.itemData.infoEmailsTemplates_id},on:{"emit":function($event){_vm.itemData.infoEmailsTemplates_id = $event.value}}}):_vm._e(),_c('Patcher',{attrs:{"itemValue":_vm.itemData.activeMemberCampaignsOnly,"itemName":"activeMemberCampaignsOnly","itemLabel":"An alle Leads aktiver Member-Kampagnen","hideLabel":true,"route":_vm.patchRoute + _vm.itemData.id,"inputElement":"switch","inputType":"","selectOptions":"","itemValueKey":"","itemTextKey":"","disabled":_vm.itemData.status === 'pending' || _vm.itemData.status === 'completed'
        ? true
        : false,"previewValue":_vm.itemData.activeMemberCampaignsOnly},on:{"emit":function($event){_vm.itemData.activeMemberCampaignsOnly = $event.value}}}),(
      !_vm.itemData.activeMemberCampaignsOnly ||
      _vm.itemData.activeMemberCampaignsOnly === '0'
    )?_c('Patcher',{attrs:{"itemValue":_vm.itemData.campaign_ids,"itemName":"campaign_ids","itemLabel":"Kampagnen-IDs","hideLabel":false,"route":_vm.patchRoute + _vm.itemData.id,"inputElement":"input","inputType":"text","selectOptions":"","itemValueKey":"","itemTextKey":"","hint":"Mehrere IDs mit Komma trennen","disabled":_vm.itemData.status === 'pending' || _vm.itemData.status === 'completed'
        ? true
        : false,"previewValue":_vm.itemData.campaign_ids},on:{"emit":function($event){_vm.itemData.campaign_ids = $event.value}}}):_vm._e(),(_vm.itemData.status !== 'completed')?_c('v-btn',{staticClass:"white--text my-4",attrs:{"color":"green","disabled":_vm.activationDisabled(),"large":""},on:{"click":function($event){return _vm.startMailing()}}},[_vm._v(" Mailing starten ")]):_vm._e(),(!_vm.activationDisabled())?_c('SendTest',{attrs:{"templateId":_vm.itemData.infoEmailsTemplates_id,"listId":_vm.itemData.id}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }